.contact-main{
  /* border: 2px solid green; */
  padding: 30px 50px 30px 50px;
  border-radius: 10px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  transform: scale(1);
  transition: transform .5s;
  letter-spacing: 1px;
  color: rgb(88, 94, 92);
}

.contact-main:hover{
  transform: scale(1.03);
}

.set{
  transform: scale(1);
  transition: transform .5s;
}

.set:hover{
  transform: scale(1.3);
}

.ct-main {
  background-color: rgba(100, 100, 100, 0.3);
  /* margin: auto; */
  box-shadow: rgb(0 0 0 / 2%) 0px 1px 3px 0px, rgb(27 31 35 / 15%) 0px 0px 0px 1px;
  padding: 20px;
  border-radius: 10px
}

